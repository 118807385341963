import React, { useState } from "react"
import { Card } from "react-bootstrap"
import * as styles from "./styles.module.scss"

const Filters = ({filterData, handleFilter}) => {

  const [activeCard, setActiveCard] = useState(0)

	return (
		<>
			<h5 className="mb-4 text-uppercase">Advocate Filters</h5>
			{filterData.map((filter, index) => (
				<Card className={`${styles.filters} ${activeCard == index ? `${styles.activeFilter}` : ""}`}>
					<Card.Body>
						<p onClick={()=>{handleFilter(filter.id); setActiveCard(index) }} className="text-capitalize">{filter.title}</p>
					</Card.Body>
				</Card>
			))}
		</>
	)
}

export default Filters