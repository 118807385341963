import React from "react"
import advocate_img from "../assets/images/blog_card.png"
import profile from "../assets/images/profile_advocate.svg"

export const content = {
	// Filters
	filters: [
		{
			title: "All advocates",
			id: "12",
		},
		{
			title: "Pro advocates",
			id: "13",
		},
		{
			title: "College advocates",
			id: "14",
		},
		{
			title: "High school advocates",
			id: "15",
		},
		{
			title: "Coach (All levels)",
			id: "16",
		},
		{
			title: "Family and friends",
			id: "17",
		},
	],

	// Advocate Cards
	advocateCard: [
		{
			image: advocate_img,
			title: "Kamali Tan",
			location: "Hermosa Beach, CA",
			rank: "Pro Advocate",
			sports: "Volleyball",
			path: "/advocateDetails",
		},
		{
			image: advocate_img,
			title: "Kamali Tan",
			location: "Hermosa Beach, CA",
			rank: "Pro Advocate",
			sports: "Volleyball",
			path: "/advocateDetails",
		},
		{
			image: advocate_img,
			title: "Kamali Tan",
			location: "Hermosa Beach, CA",
			rank: "Pro Advocate",
			sports: "Volleyball",
			path: "/advocateDetails",
		},
		{
			image: advocate_img,
			title: "Kamali Tan",
			location: "Hermosa Beach, CA",
			rank: "Pro Advocate",
			sports: "Volleyball",
			path: "/advocateDetails",
		},
		{
			image: advocate_img,
			title: "Kamali Tan",
			location: "Hermosa Beach, CA",
			rank: "Pro Advocate",
			sports: "Volleyball",
			path: "/advocateDetails",
		},
		{
			image: advocate_img,
			title: "Kamali Tan",
			location: "Hermosa Beach, CA",
			rank: "Pro Advocate",
			sports: "Volleyball",
			path: "/advocateDetails",
		},
	],

	// Want Advocate
	wantAdvocate: {
		title: "WANT TO BE AN ADVOCATE?",
		text: "Athlete advocates are athletes, coaches, and family & friends who understand the unique challenges athletes experience as well as the barriers they face when seeking mental health care. Athlete advocates provide their support, create space for open conversations, and connect their peers to tailored resources and therapy options. To learn more about what it means to be an athlete advocate and how to serve as a mental health resource check out our Athlete Peer Resource Guide.",
		cards: [
			{
				image: profile,
				title: (
					<>
						Athlete Advocate <br /> <br />
					</>
				),
			},
			// {
			// 	image: profile,
			// 	title: (
			// 		<>
			// 			College Advocate <br /> <br />
			// 		</>
			// 	),
			// },
			// {
			// 	image: profile,
			// 	title: " High School Advocate",
			// },
			// {
			// 	image: profile,
			// 	title: (
			// 		<>
			// 			Coach (all levels) <br /> <br />
			// 		</>
			// 	),
			// },
			// {
			// 	image: profile,
			// 	title: (
			// 		<>
			// 			Family & Friends <br /> <br />
			// 		</>
			// 	),
			// },
		],
	},
}
