import React from "react"
import Paragraph from "../../../../components/shared/Paragraph"
import Section from "../../../../components/shared/Section"
import SectionHeading from "../../../../components/shared/Sectionheading"
import { Card, Col, Container, Image, Row } from "react-bootstrap"
import ButtonSecondary from "../../../../components/shared/ButtonSecondary"
import { Link } from "gatsby"

const WantAdvocate = ({ title, text, cardData }) => {
	return (
		<Section>
			<Container>
				<Row className="justify-content-center">
					<Col md={9}>
						<SectionHeading className="text-center mt-0 mt-md-5 pt-0 pt-md-5">{title}</SectionHeading>
						<Paragraph className="text-center">{text}</Paragraph>
					</Col>
				</Row>
				<Row className="mt-5 mb-5 justify-content-center ">
					{cardData.map((cards) => (
						<Col md={2} sm={8} xs={8} className="mt-md-0 mt-4">
							<Card>
								<Card.Body>
									<div className="text-center">
										<Image src={cards.image} fluid className=" mt-3" />
										<h6 className="fw-bold mt-3">{cards.title}</h6>
										<Link to="/advocate-signup">
											<ButtonSecondary className="mb-3 mt-3">Sign up</ButtonSecondary>
										</Link>
									</div>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Container>
		</Section>
	)
}

export default WantAdvocate
