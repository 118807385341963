import React from "react"
import { Card, Col, Image, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import location_icon from "../../../../assets/icons/location.svg"
import { navigate } from "gatsby"

const AdvocateCard = ( props ) => {
	return (
		<Row>
			{props.advocates &&
				props.advocates.map( ( cards, index ) => (
					<Col md={4} className="mt-4 d-flex">
					<Card className={styles.cards}>
						<div className={styles.textCenter}>
						<Card.Img className={styles.imgmain} variant="top" src={cards.image_url} />
						</div>
						<Card.Body>
							<Row>
								<Col xs={6}>
									<p className={styles.name}>{cards.name}</p>
									<p className={styles.rank}>{cards.level.title}</p>
								</Col>
								<Col xs={6}>
									<p className={styles.location}>
										<span>
											<Image src={location_icon} className="me-2" fluid width={16} />
										</span>
										{cards.address}
									</p>
								</Col>
								<Col md={12}>
									<p className={styles.sports}>{cards.sport.title}</p>
								</Col>
								<Col md={12} style={{ borderTop: "1px solid #B3B3B3" }}>
									<div className="text-center pt-3 pb-2">
										<a
											onClick={() => navigate(`/advocateDetails/?id=${cards.id}`)}
											className="text-primary"
											style={{cursor: "pointer"}}>
											View information
										</a>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
					))}
		</Row>
	)
}

export default AdvocateCard
