import React from "react"
import { Main } from "../components/layout"
import AdvocateWrapper from "../components/pages/advocate/AdvocateWrapper"
import WantAdvocate from "../components/pages/advocate/WantAdvocate"
import { content } from "../content/advocate"

const Advocate = () => {
	return (
		<>
			<Main>
				<AdvocateWrapper filterContent={content.filters} cardContent={content.advocateCard} />
				<WantAdvocate
					title={content.wantAdvocate.title}
					text={content.wantAdvocate.text}
					cardData={content.wantAdvocate.cards}
				/>
			</Main>
		</>
	)
}

export default Advocate
