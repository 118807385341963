import React, { useEffect } from "react"
import Section from "../../../../components/shared/Section"
import { Col, Container, Image, Pagination, Row } from "react-bootstrap"
import Filters from "../Filters"
import AdvocateCard from "../AdvocateCard"
import line from "../../../../assets/images/line.png"
import axios from "axios"
import SpinLoad from "../../../shared/Spinner"

const AdvocateWrapper = ({ cardContent, filterContent }) => {
	const [advocates, setAdvocates] = React.useState([])
	const [index, setIndex] = React.useState(1)
	const [loading, setLoading] = React.useState(false)

	useEffect(() => {
		setLoading(true)
		axios.get(`https://api.galeahealth.com/profile/advocate?search=&offset=0&limit=9`,  {
		}).then(res => {
			setAdvocates(res.data.data)
			setLoading(false)
			console.log(res.data.data)
		}).catch(err => {
			console.log(err)
		})
	} , [])

	const handleFilter = (id) => {
		setLoading(true)
		if (id === "12") {
			axios.get(`https://api.galeahealth.com/profile/advocate?search=&offset=0&limit=9`,  {
			}).then(res => {
				setAdvocates(res.data.data)
				setLoading(false)
			}).catch(err => {
				console.log(err)
			})
		} else {
			axios.get(`https://api.galeahealth.com/profile/advocate?search=&offset=0&limit=9&level_id=${id}`, {}).then(res => {
				setAdvocates(res.data.data)
				setLoading(false)
			}).catch(err => {
				console.log(err)
			})
		}
	}

	const handlePageChange = () => {
		setIndex(index + 1);
		axios.get(`https://api.galeahealth.com/profile/advocate?search=&offset=${index * 9}&limit=9`,  {
		}).then(res => {
			setAdvocates(res.data.data)
			console.log(res.data.data)
		}).catch(err => {
			console.log(err)
		})
	}

	return (
		<Section>
			<Container>
				<Row>
					<Col md={4} className="mb-5">
						<Filters handleFilter={handleFilter}  filterData={filterContent} />
					</Col>
					{loading ? (
						<Col md={8}>
						<div className="d-flex justify-content-center align-items-center" style={{width:'100%',height:'75%'}}>
							<SpinLoad />
						</div>
						</Col>
					) : (
					<Col md={8}>
						<AdvocateCard  cardData={cardContent} advocates={advocates} />
						<Row className="justify-content-center mt-5">
							<Col md={2} xs={9}>
								<div className="text-center">
									<Pagination>
										{/*<Pagination.First />*/}
										<Pagination.Prev  />
										<Pagination.Item>
											{index}
										</Pagination.Item>
										<Pagination.Next onClick={handlePageChange} />
										{/*<Pagination.Last />*/}
									</Pagination>
								</div>
							</Col>
						</Row>
					</Col>
					)}
				</Row>
			</Container>
			<Container fluid className="ps-md-0 pe-md-0">
				<Row>
					<Col md={12}>
						<Image src={line} fluid className="w-100" />
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default AdvocateWrapper
